<template>
  <nav id="navbar" className="fixed z-10 flex w-full items-center justify-between flex-wrap m-auto top-0 animated">
    <div
      class='bg-white ease transition-all grid grid-flow-col sm:grid-cols-[auto_auto_auto] md:place-items-[unset] h-[auto] md:grid-cols-[auto_40rem] items-center md:justify-between w-full'>
      <button @click="openNav" class="z-[12] h-full md:hidden">
        <svg :class="[
          'w-[1.5rem] h-[1.5rem] ml-[1rem]',
          scrolledDown ? 'fill-white' : 'fill-black'
        ]" viewBox="0 0 24 24" stroke="currentColor" jsname="B1n9ub" focusable="false">
          <g id="close-svg" transform="translate(12,12)">
            <path class="bar" d="M-9 -5 L9 -5" fill="none" stroke-width="2"></path>
            <path class="bar" d="M-9 0 L9 0" fill="none" stroke-width="2"></path>
            <path class="bar" d="M-9 5 L9 5" fill="none" stroke-width="2"></path>
          </g>
        </svg>
      </button>

      <!-- mobile view nav -->
      <section @click="openNav"
        :class="['fixed w-[100vw] top-0 z-10 h-[100vh] bg-black bg-opacity-90', button1Active ? 'inline-block' : 'hidden']">
      </section>
      <section
        :class="['transition-all flex h-[100vh] fixed top-0 z-[11] left-0 bg-opacity-95 overflow-hidden', button1Active ? 'w-[15rem]' : 'w-[0]', scrolledDown ? 'bg-white' : 'bg-white']">
        <ul class="flex pl-[2rem] justify-start items-start mt-[6rem] flex-col font-light gap-[1rem] z-3 open-sans align-center">
          <li>
            <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem] pt-[0]" to="/">Home</RouterLink>
          </li>
          <li>
            <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/about">About Us
            </RouterLink>
          </li>
          <li>
            <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/services">Services
            </RouterLink>
          </li>
          <li>
            <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/projects">Projects
            </RouterLink>
          </li>
          <li>
            <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/contact">Contact Us
            </RouterLink>
          </li>
          <li>
            <img class="px-[0.625rem] py-[0.75rem] cursor-pointer" src="" />
          </li>
        </ul>
      </section>

      <!-- logo -->
      <RouterLink
        class="sm:bottom-[.2rem] md:bottom-[0rem] place-self-center left-[0rem] md:pb-[.3rem] relative ml-[0rem] md:ml-[1rem] h-[auto]"
        to="/">
        <img className="w-[5.0625rem] h-[auto] object-contain" src="../assets/vesta-atlantic-logo.png" />
      </RouterLink>

      <!-- desktop view nav -->
      <section class="flex items-center justify-end">
        <section class="hidden md:inline-block">
          <ul class="flex justify-end open-sans align-center">
            <li class="">
              <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/">Home</RouterLink>
              <div class="dot"></div>
            </li>
            <li>
              <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/about">About Us
              </RouterLink>
              <div class="dot"></div>
            </li>
            <li>
              <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/services">Services
              </RouterLink>
              <div class="dot"></div>
            </li>
            <li>
              <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/projects">Projects
              </RouterLink>
              <div class="dot"></div>
            </li>
            <li>
              <RouterLink activeClass="open-sans-active" class="px-[0.625rem] py-[0.75rem]" to="/contact">Contact Us
              </RouterLink>
            </li>
          </ul>
        </section>

        <!-- searchbar -->
        <section @click="openSearchBarFxn"
          :class="['flex cursor-pointer items-center sm:justify-end sm:place-self-end justify-center pr-[1rem] h-[3rem] md:w-[3rem] md:h-[3rem]', hideSearchIcon ? 'hidden' : 'flex']">

          <svg :class="[
            'w-[1.5rem] h-[1.5rem]',
            scrolledDown ? 'fill-black' : 'fill-black'
          ]" viewBox="0 0 24 24" focusable="false">
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </section>

        <SearchBar :class="['absolute z-[23]', hideSearchIcon ? 'inline-block' : 'hidden']"
          @load-component="loadComponent" />
        <section @click="openSearchBarFxn"
          :class="['fixed z-[20] top-0 bg-black bg-opacity-50 w-[100%] h-[100vh]', hideSearchIcon ? 'inline-block' : 'hidden']">
        </section>

      </section>
    </div>
  </nav>
</template>

<script>
import SearchBar from './SearchBar.vue';
export default {
  name: 'NavBar',
  $el: 'navbar',
  mounted() {
    this.$emit('load-images');
  },
  components: {
    SearchBar,
  },
  data() {
    return {
      scrolledDown: false,
      scrollThreshold: 50, // Adjust this threshold as needed
      button1Active: false,
      currentComponent: null,
      openSearch: false,
      hideSearchIcon: false,
    };
  },
  methods: {
    handleScroll() {
      this.scrolledDown = window.scrollY > this.scrollThreshold; // Update based on a condition
    },
    openNav() {
      this.button1Active = !this.button1Active;
      const menuButton = this.$el.querySelector("#close-svg");

      menuButton.classList.toggle("close");
    },
    async loadComponent(componentName) {
      this.currentComponent = (await import(`./${componentName}.vue`)).default;
    },
    openSearchBarFxn() {
      this.openSearch = !this.openSearch;
      this.hideSearchIcon = !this.hideSearchIcon;
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>