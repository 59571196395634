<template>
    <section class="flex flex-wrap items-center justify-center">
      <section class="grid grid-rows-[minmax(18rem, auto)] mt-[4rem] md:mt-[4rem]">
        <img
          :src="imgSrc"
          :alt="imgAlt"
          class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
        />
      </section>
      <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
        {{ headerText }}
      </h3>
      <p class="text-center px-[2rem]">
        {{ paragraphText }}
      </p>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ServiceCard',
    mounted() {
    this.$emit('load-images');
  },
    props: {
      imgSrc: {
        type: String,
        required: true,
      },
      imgAlt: {
        type: String,
        required: true,
        default: 'service image',
      },
      headerText: {
        type: String,
        required: true,
      },
      paragraphText: {
        type: String,
        required: true,
      },
    },
  };
  </script>
