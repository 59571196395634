<template>
    <section
        class="contact-us-bg flex justify-center align-center w-[100%] h-[19.375rem] relative bg-no-repeat bg-cover bg-center">
        <section
            class="flex justify-center items-center text-center h-[auto] w-[100%] bg-[#212121] bg-opacity-60 text-white text sm:text-[3.167rem] md:text-[3.75rem] text-[2.5rem]">
            <h1 class="flex items-center relative text-center
        before:content-['']
        before:border-b-[#599736]
        before:border-solid
        before:border-b-[.5rem]
        before:w-[100px]
        before:absolute
        before:left-[19%]
        sm:before:left-[25%]
        md:before:left-[29%]
        before:bottom-[-1rem]
        before:flex
        before:items-center
        before:justify-center
        roboto-medium
        ">Contact Us</h1>
        </section>
    </section>
    <section class="open-sans leading-[1.38] flex flex-wrap gap-[1.2rem] p-[1rem_2.5rem] md:p-[2rem_6.5rem]">
        <p class="w-[100%]">
            If you are interested in sustainable energy solutions, feel free to get in touch with us at any time.
        </p>

        <section class="flex justify-center flex-wrap md:flex-nowrap md:items-center w-[100%] gap-[1.5rem] mt-[1rem]">

            <p class="flex flex-wrap justify-center md:justify-start">
            <img class="w-[2rem] object-contain h-[3rem]" src="../assets/imgs/contact-us-icons/icons8-telephone-100.png" alt="telephone icon" />
            <span class="w-[100%]">
                <strong class="text-[#222222]">Telephone</strong>: +234 (0) 903 452 4697
            </span>
        </p>

        <p class="flex flex-wrap justify-center md:justify-start">
            <img class="w-[2rem] object-contain h-[3rem]" src="../assets/imgs/contact-us-icons/icons8-email-100.png" alt="Email icon" />
            <span class="inline-block w-[100%]">
                <strong class="text-[#222222]">Email</strong>: 
                <a class="underline" href="mailto:info@vestaatlantic.com">info@vestaatlantic.com</a>
            </span>
        </p>
        
        <p class="flex flex-wrap justify-center md:justify-start">
            <img class="w-[2rem] object-contain h-[3rem]" src="../assets/imgs/contact-us-icons/icons8-linkedin.svg" alt="Linkedin icon" />
            <span class="w-[100%]">
                <strong class="text-[#222222]">LinkedIn</strong>: <a class="underline" href="https://www.linkedin.com/company/vesta-atlantic/about/" target="_blank">Click Here</a>
            </span>
        </p>
    </section>
    </section>

    <section class="flex flex-wrap w-[100%] h-auto justify-center items-center  p-[1rem_2.5rem] md:p-[2rem_6.5rem] gap-[1.5rem] ">
        <div class="w-[100%] h-[24rem] z-[1]" id="map"></div>
        <h2 class="w-[100%] text-center roboto text-[1.5rem] font-[700]">RIVERS - HEAD OFFICE</h2>
        <p>No. 2B General Diriyai Street, GRA, Port Harcourt, Rivers State</p>
    </section>

    <section class="flex flex-wrap w-[100%] h-auto p-[1rem_2.5rem] md:p-[2rem_6.5rem] gap-[1rem]">
        <h2 class="text-[1.5rem] roboto font-[600] w-[100%]">Careers</h2>
        <p class="w-[100%] inline-block">Vesta Atlantic welcomes candidates looking to become part of our team. We encourage highly qualified and experienced individuals interested in working with us to submit their resume to our Career department: <a class="underline" href="mailto:info@vestaatlantic.com">info@vestaatlantic.com</a></p>
        <p class="w-[100%] inline-block">
            Current Job Listings - <RouterLink class="underline cursor-pointer" to="/careers" target="_blank">Click Here</RouterLink>
        </p> 
    </section>
</template>

<script>
import { ref } from 'vue';
import { useHead } from '@vueuse/head';
import L from 'leaflet'; // Ensure Leaflet is imported
export default {
    name: 'ContactUsPage',
    mounted() {
        this.$emit('load-images');
    // Create the map
    this.map = L.map('map').setView([4.82599042760086, 6.99309686012064], 13);

    // Add a tile layer
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    L.marker([4.82599042760086, 6.99309686012064]).addTo(this.map)
      .bindPopup('No. 2B General Diriyai Street, GRA, Port Harcourt, Rivers State')
      .openPopup();
  },
  setup() {
    const pageTitle = ref('About - Vesta Atlantic');
    const pageDescription = ref(`Vesta Atlantic Careers Page`);
    const pageOgDescription = ref(`If you are interested in sustainable energy solutions, feel free to get in touch with us at any time.`);
    const pageImage = ref('https://i.ibb.co/PDdQgqg/logo.jpg');
    const pageUrl = ref('https://www.vestaatlantic.com/contact');

    useHead({
      title: pageTitle.value,
      meta: [
        { name: 'description', content: pageDescription.value },
        { property: 'og:title', content: pageTitle.value },
        { property: 'og:description', content: pageOgDescription.value },
        { property: 'og:image', content: pageImage.value },
        { property: 'og:url', content: pageUrl.value },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: pageTitle.value },
        { name: 'twitter:description', content: pageOgDescription.value },
        { name: 'twitter:image', content: pageImage.value }
      ]
    });

    return {
      pageTitle,
      pageDescription,
      pageOgDescription,
      pageImage,
      pageUrl
    };
  }
}
</script>