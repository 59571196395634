<template>
  <section
    class="projects-bg flex justify-center align-center mb-[4rem] w-[100%] h-[19.375rem] relative bg-no-repeat bg-cover bg-center">
    <section
      class="flex justify-center items-center text-center h-[auto] w-[100%] bg-[#212121] bg-opacity-60 text-white text sm:text-[3.167rem] md:text-[3.75rem] text-[2.5rem]">
      <h1 class="flex items-center relative text-center
        before:content-['']
        before:border-b-[#599736]
        before:border-solid
        before:border-b-[.5rem]
        before:w-[100px]
        before:absolute
        before:left-[19%]
        sm:before:left-[25%]
        md:before:left-[29%]
        before:bottom-[-1rem]
        before:flex
        before:items-center
        before:justify-center
        roboto-medium
        ">
        Projects
      </h1>
      <hr />
    </section>
  </section>

  <section
    class="flex w-[100%] flex-wrap p-[2rem_3rem] mb-[4rem] md:mb-[0] md:p-[2rem_4.5rem] items-center justify-center gap-[3rem]">
    <section class="flex w-[100%] mb-[2rem] md:mb-[0] h-fit justify-center">

      <p class="w-[100%] text-[1.1rem] md:p-[0rem_10%] text-center">Vesta Atlantic has been directly involved in
        the execution and delivery of projects & services for the following
        companies:</p>
    </section>


    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mb-[4rem] md:w-[90%]">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-gray-700 text-xs md:text-[0.95rem] uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">
              Company
            </th>
            <th scope="col" class="px-6 py-3">
              Services
            </th>
            <th scope="col" class="px-6 py-3">
              Date
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody class="text-sm md:text-[1rem]">
          <ProjectTableRows
      v-for="(project, index) in projects"
      :key="index"
      :companyName="project.companyName"
      :work="project.work"
      :dateMY="project.dateMY"
      :completionStatus="project.completionStatus"
      :imgURL="project.imgURL"
    />
        </tbody>
      </table>
    </div>

  </section>
</template>
<script>
import { ref } from 'vue';
import { useHead } from '@vueuse/head';
import ProjectTableRows from './ProjectTableRows.vue';

export default {
  name: 'ProjectPage',
  mounted() {
    this.$emit('load-images');
  },
  data() {
    return {
      projects: [
        {
          companyName: "ND Western",
          work: "Industrial Relations Consultancy (CBA Negotiation)",
          dateMY: "2024",
          completionStatus: "Ongoing",
          imgURL: "imgs/clients/ND-Western-1424x802.jpg"
        },
        {
          companyName: "NNPC",
          work: "Promotion Interview Training Workshop",
          dateMY: "2024",
          completionStatus: "Ongoing",
          imgURL: "imgs/regulatory-bodies/nnpc.png"
        },
        {
          companyName: "NNPC Benin",
          work: "Promotion Interview Training Workshop",
          dateMY: "2024",
          completionStatus: "Ongoing",
          imgURL: "imgs/regulatory-bodies/nnpc.png"
        },
        {
          companyName: "NNPC",
          work: "Talent Management and Consulting Services",
          dateMY: "2023",
          completionStatus: "Ongoing",
          imgURL: "imgs/regulatory-bodies/nnpc.png"
        },
        {
          companyName: "NNPC",
          work: "Personalized Interview Stimulation Session",
          dateMY: "2023",
          completionStatus: "Completed",
          imgURL: "imgs/regulatory-bodies/nnpc.png"
        },
        {
          companyName: "NNPC",
          work: "Oral Interview Preparation Training",
          dateMY: "2023",
          completionStatus: "Completed",
          imgURL: "imgs/regulatory-bodies/nnpc.png"
        }
      ]
    }
  },
  components:{
    ProjectTableRows,
  },
  setup() {
    const pageTitle = ref('Projects - Vesta Atlantic');
    const pageDescription = ref(`Vesta Atlantic has been directly involved in
        the execution and delivery of projects & services for the following
        companiesVesta Atlantic has been directly involved in
        the execution and delivery of projects & services for the following
        companies.`);
    const pageOgDescription = ref(`Vesta Atlantic has been directly involved in
        the execution and delivery of projects & services for the following
        companies.`);
    const pageImage = ref('https://i.ibb.co/PDdQgqg/logo.jpg');
    const pageUrl = ref('https://www.vestaatlantic.com/projects');

    useHead({
      title: pageTitle.value,
      meta: [
        { name: 'description', content: pageDescription.value },
        { property: 'og:title', content: pageTitle.value },
        { property: 'og:description', content: pageOgDescription.value },
        { property: 'og:image', content: pageImage.value },
        { property: 'og:url', content: pageUrl.value },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: pageTitle.value },
        { name: 'twitter:description', content: pageOgDescription.value },
        { name: 'twitter:image', content: pageImage.value }
      ]
    });

    return {
      pageTitle,
      pageDescription,
      pageOgDescription,
      pageImage,
      pageUrl
    };
  }
}
</script>