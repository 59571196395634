<template>
    <section
        class="contact-us-bg flex justify-center align-center w-[100%] h-[19.375rem] relative bg-no-repeat bg-cover bg-center">
        <section
            class="flex justify-center items-center text-center h-[auto] w-[100%] bg-[#212121] bg-opacity-60 text-white text sm:text-[3.167rem] md:text-[3.75rem] text-[2.5rem]">
            <h1 class="flex items-center relative text-center
        before:content-['']
        before:border-b-[#599736]
        before:border-solid
        before:border-b-[.5rem]
        before:w-[100px]
        before:absolute
        before:left-[19%]
        sm:before:left-[25%]
        md:before:left-[29%]
        before:bottom-[-1rem]
        before:flex
        before:items-center
        before:justify-center
        roboto-medium
        ">Careers</h1>
        </section>
    </section>
    <section class="open-sans leading-[1.38] flex flex-wrap gap-[1.2rem] p-[1rem_2.5rem] md:p-[2rem_6.5rem]">
        <p class="w-[100%]">
            No job listings yet. Please check back later!
        </p>
       </section>
</template>

<script>
import { ref } from 'vue';
import { useHead } from '@vueuse/head';

export default {
    name: 'CareersPage',
    mounted() {
    this.$emit('load-images');
  },
  setup() {
    const pageTitle = ref('About - Vesta Atlantic');
    const pageDescription = ref(`Vesta Atlantic Careers Page`);
    const pageOgDescription = ref(`Are you passionate about forward-thinking energy, environmental protection,
                restoration, and sustainable development in the energy sector? Join us today!`);
    const pageImage = ref('https://i.ibb.co/PDdQgqg/logo.jpg');
    const pageUrl = ref('https://www.vestaatlantic.com/careers');

    useHead({
      title: pageTitle.value,
      meta: [
        { name: 'description', content: pageDescription.value },
        { property: 'og:title', content: pageTitle.value },
        { property: 'og:description', content: pageOgDescription.value },
        { property: 'og:image', content: pageImage.value },
        { property: 'og:url', content: pageUrl.value },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: pageTitle.value },
        { name: 'twitter:description', content: pageOgDescription.value },
        { name: 'twitter:image', content: pageImage.value }
      ]
    });

    return {
      pageTitle,
      pageDescription,
      pageOgDescription,
      pageImage,
      pageUrl
    };
  }
}
</script>