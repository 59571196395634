<template>

  <!--  -->
  <footer
    class="bg-[#599736] footer-background bg-cover flex flex-wrap md:gap-[0rem] w-full justify-center content-center gap-[1rem] h-auto">
    <section
      class="flex flex-wrap md:gap-[0rem] w-full justify-center md:justify-around md:px-[10rem] xl:px-[12rem] px-[1rem] content-center gap-[1rem] md:mt-[1.5rem] h-auto">
      <section
        class="grid w-full gap-[.5rem] md:w-[auto] grid-rows-[4rem_1fr] h-auto justify-center align-middle items-center align-center text-white mt-[1rem] md:mt-[0rem]">
        <article class="w-full">
          <h2 class="roboto text-[1.2rem] text-center md:text-left uppercase font-[500]">About Us</h2>
        </article>

        <section class="self-start w-[100%]">
          <section
            class="transition-all flex h-auto bg-opacity-95 overflow-hidden w-[20rem] place-self-start items-start justify-center md:justify-start">
            <ul
              class="flex justify-center md:justify-start items-start text-center md:text-left flex-col font-light text-[1rem] gap-[1rem] z-3 open-sans align-center">
              <li class="w-[100%] md:w-auto">
                <RouterLink class="px-[0.625rem]" :to="{ path: '/about', hash: '#who-we-are' }">
                  Who we are
                </RouterLink>
              </li>
              <li class="w-[100%] md:w-auto">
                <RouterLink class="px-[0.625rem]" :to="{ path: '/about', hash: '#our-mission' }">
                  Our Mission
                </RouterLink>
              </li>
              <li class="w-[100%] md:w-auto">
                <RouterLink class="px-[0.625rem]" :to="{ path: '/about', hash: '#our-vision' }">
                  Our Vision
                </RouterLink>
              </li>
              <li class="w-[100%] md:w-auto">
                <RouterLink class="px-[0.625rem]" :to="{ path: '/about', hash: '#our-commitment' }">
                  Our Commitment to Sustainability
                </RouterLink>
              </li>
            </ul>
          </section>

          <!-- Social -->
          <section
            class="mt-[2rem] md:mt-[4rem] w-[100%] md:w-[14rem] h-auto grid-flow-col grid-cols-[auto,_auto,_auto] relative z-[4] grid place-items-center">
            <a class="text-white p-[1rem] cursor" href="https://www.linkedin.com/company/vesta-atlantic/about/"
              target="_blank">
              <img class="w-[1.5rem] h-[1.5rem] object-contain" src="../assets/imgs/footer/linkedin.png"
                alt="LinkedIn" />
            </a>
            <a class="p-[1rem] cursor" target="_blank" href="https://www.instagram.com/vestaatlantic/">
              <img class="w-[1.5rem] h-[1.5rem] object-contain" src="../assets/imgs/footer/instagram.png"
                alt="instagram" />
            </a>
            <a class="p-[1rem] cursor" href="https://x.com/vestaatlantic" target="_blank">
              <img class="w-[1.5rem] h-[1.5rem] object-contain" src="../assets/imgs/footer/twitter.png" alt="x" />
            </a>

            <a class="p-[1rem] cursor" href="https://www.threads.net/@vestaatlantic" target="_blank">
              <img class="w-[1.5rem] h-[1.5rem] object-contain" src="../assets/imgs/footer/threads.png" alt="threads" />
            </a>
            
          </section>

        </section>
      </section>

      <!-- Quick links -->
      <section
        class="grid gap-[.5rem] grid-rows-[4rem_1fr] text-center content-center h-auto justify-center items-center align-center text-white">
        <article class="w-full">
          <h2 class="roboto text-[1.2rem] font-[500] md:text-left uppercase">Quick Links</h2>
        </article>
        <section class='transition-all flex h-auto bg-opacity-95 overflow-hidden w-[20rem] place-self-start'>
          <ul
            class="flex justify-center items-center md:items-start  flex-col font-light text-[1rem] w-[100%] gap-[1rem] z-3 open-sans align-center">
            <li class="w-[100%] md:w-auto">
              <RouterLink class="px-[0.625rem] py-[0.75rem] pt-[0]" to="/">Home
              </RouterLink>
            </li>
            <li class="w-[100%] md:w-auto">
              <RouterLink class="px-[0.625rem] py-[0.75rem]" to="/about">About Us
              </RouterLink>
            </li>
            <li class="w-[100%] md:w-auto">
              <RouterLink class="px-[0.625rem] py-[0.75rem]" to="/services">Services
              </RouterLink>
            </li>
            <li class="w-[100%] md:w-auto">
              <RouterLink class="px-[0.625rem] py-[0.75rem]" to="/projects">Projects
              </RouterLink>
            </li>
            <li class="w-[100%] md:w-auto">
              <RouterLink class="px-[0.625rem] py-[0.75rem]" to="/contact">Contact Us
              </RouterLink>
            </li>
            <li class="w-[100%] md:w-auto">
              <RouterLink class="px-[0.625rem] py-[0.75rem]" to="/careers">Careers
              </RouterLink>
            </li>
          </ul>
        </section>
      </section>

      <!-- Company information -->
      <section
        class="grid grid-rows-[4rem_1fr] mb-[1.5rem] mt-[1rem] md:mt-0 h-auto justify-center items-center align-center text-white">
        <article class="w-full">
          <h2 class="roboto text-[1.2rem] font-[500] text-center md:text-left uppercase">Company Information</h2>
        </article>
        <section
          class='transition-all text-center md:text-left text-[1rem] flex flex-wrap gap-[1rem] h-auto bg-opacity-95 overflow-hidden w-[20rem] place-self-start'>

          <p class="inline-block w-full">
            <strong>
              Head office:
            </strong>
            <span>
              No. 2B General Diriyai Street, GRA, Port Harcourt, Rivers State
            </span>
          </p>

          <p class="flex flex-wrap w-full text-center md:text-left">
            <span class="w-[100%] mb-[.5rem]">
              <strong>
                Corporate Contact:
              </strong>
            </span>
            <span class="w-[100%] mb-[.5rem]">
              Telephone: +234 (0) 903 452 4697
            </span>

            <span class="w-[100%]">
              Email: <a class="cursor underline" href="mailto:info@vestaatlantic.com">
                info@vestaatlantic.com
              </a>
            </span>
          </p>
        </section>
      </section>

      <!-- Copyright -->
      <section class="flex flex-wrap justify-center h-[2rem] mb-[1rem] w-full md:items-center">
        <p class="md:py-[0] inline-block w-[100%] text-white text-center text-[.75rem] roboto font-[400] leading-[1.38]">
          Copyright © {{ new Date().getFullYear() }} Vesta Atlantic. All Rights Reserved.
        </p>
      </section>
    </section>
  </footer>
</template>
<script>
export default {
  name: 'FooterComponent'
}
</script>