<template>
  <div class="p-4 grid grid-cols-[minmax(10rem,_50rem)] grid-rows-[1fr, auto] h-auto justify-center w-[100%]">
    <input type="text" v-model="query" @input="onInput" placeholder="Search..."
      class="w-[100%] p-2 border rounded shadow mt-[1rem]" />
    <div v-if="results.length > 0" class="inline-block mb-4 h-auto">
      <ul class="list-none p-0 flex flex-wrap absolute mt-[1rem]">
        <li v-for="(result, index) in results" :key="index" @click="navigateToComponent(result.item.path)"
          class="p-2 border-b cursor-pointer text-white hover:bg-gray-200 mb-[.5rem] w-[100%]">
          {{ result.item.title }}
        </li>
      </ul>
    </div>
    <div v-else-if="query.length > 0" class="mt-4 text-red-500">
      No results found.
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';

export default {
  name: 'SearchBar',
  data() {
    return {
      query: '',
      results: [],
      fuse: null,
    };
  },
  created() {
    const components = [
      {
        title: 'Home',
        content: `Our company profile showcases our
        commitment to sustainable energy
        solutions and exemplary client service,
        underpinning our vision for a cleaner and
        efficient future.`,
        component: 'LandingPage',
        path: '/'
      },
      { title: 'About Us', content: 'Learn more about us here.', component: 'AboutPage', path: '/about' },
      { title: 'Contact', content: 'Get in touch with us.', component: 'ContactPage', path: '/contact' },
      { title: 'Careers', content: 'Read our first blog post.', component: 'CareersPage', path: '/careers' },
      { title: 'Services', content: 'Discover our services.', component: 'ServicesPage', path: '/services' },
      { title: 'Projects', content: 'Check out our portfolio.', component: 'ProjectPage', path: '/projects' },
    ];

    const options = {
      keys: ['title', 'content'],
    };

    this.fuse = new Fuse(components, options);
  },
  methods: {
    onInput() {
      this.search();
    },
    search() {
      if (this.query.length > 0) {
        this.results = this.fuse.search(this.query);
      } else {
        this.results = [];
      }
    },

    loadComponent(componentName) {
      this.$emit('load-component', componentName);
    },
      navigateToComponent(path) {
        this.$router.push(path);
      }
  },
};
</script>