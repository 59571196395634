/* eslint-disable */
<template>
  <main>
    <section class="home-background bg-cover bg-no-repeat flex sticky top-0 w-[100%] h-[100vh]">
      <div
        class="circle w-[18rem] h-[18rem] rounded-[9rem] border-none hidden md:inline-block bg-white bg-opacity-80 absolute z-2 bottom-[2rem] left-[4.2rem]">
      </div>
      <img className="w-[14rem] h-[auto] object-contain absolute z-3 hidden md:inline-block bottom-[4.1rem] left-[6rem]"
        src="../assets/vesta-atlantic-logo.png" />
    </section>

    <button class="flex justify-center w-[100%] absolute bottom-0 z-3" @click="scrollToBottom">
      <svg class="down-svg w-[3rem] fill-[black]" viewBox="0 0 24 24" focusable="false">
        <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
        <path d="M0-.75h24v24H0z" fill="none"></path>
      </svg>
    </button>

    <section class="bg-[#fdf2e5] relative z-[7] w-full flex justify-center py-[1.5rem] px-[2rem] md:px-[7rem] ">
      <h1 class="montserrat text-[1.3667em] md:text-[2.1667rem] roboto-light tracking-normal leading-normal">
        Powering a Sustainable Future: Delivering energy solutions that matter today and innovate for tomorrow.
      </h1>
    </section>
  </main>
</template>

<script>
import { ref } from 'vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'LandingPage',
  mounted() {
    this.$emit('load-images');
  },
  methods: {
    scrollToBottom() {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }
  },
  setup() {
    const pageTitle = ref('Vesta Atlantic');
    const pageDescription = ref(`Our company profile showcases our
      commitment to sustainable energy
      solutions and exemplary client service,
      underpinning our vision for a cleaner and
      efficient future.`);
    const pageOgDescription = ref(`We are a forward-thinking energy servicing company dedicated to revolutionizing how
      energy is harnessed, distributed, and utilized. We are positioned to undertake intricate
      projects for diverse clients in the energy sector.`);
    const pageImage = ref('https://i.ibb.co/PDdQgqg/logo.jpg');
    const pageUrl = ref('https://www.vestaatlantic.com');

    useHead({
      title: pageTitle.value,
      meta: [
        { name: 'description', content: pageDescription.value },
        { property: 'og:title', content: pageTitle.value },
        { property: 'og:description', content: pageOgDescription.value },
        { property: 'og:image', content: pageImage.value },
        { property: 'og:url', content: pageUrl.value },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: pageTitle.value },
        { name: 'twitter:description', content: pageOgDescription.value },
        { name: 'twitter:image', content: pageImage.value }
      ]
    });

    return {
      pageTitle,
      pageDescription,
      pageOgDescription,
      pageImage,
      pageUrl
    };
  }
}
</script>
