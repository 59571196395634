<template>
  <section
    class="services-bg flex justify-center align-center w-[100%] h-[19.375rem] relative bg-no-repeat bg-cover bg-center">
    <section
      class="flex justify-center items-center text-center h-[auto] w-[100%] bg-[#212121] bg-opacity-60 text-white text sm:text-[3.167rem] md:text-[3.75rem] text-[2.5rem]">
      <h1 class="flex items-center relative text-center
        before:content-['']
        before:border-b-[#599736]
        before:border-solid
        before:border-b-[.5rem]
        before:w-[100px]
        before:absolute
        before:left-[19%]
        sm:before:left-[25%]
        md:before:left-[29%]
        before:bottom-[-1rem]
        before:flex
        before:items-center
        before:justify-center
        roboto-medium
        ">Services</h1>
    </section>
  </section>

  <section class="flex md:flex-nowrap flex-wrap  p-[1rem_2.5rem] md:p-[2rem_6.5rem] gap-[.5rem]">
    <section class="md:w-[50%] mb-[2rem] md:mb-[0]">
      <h2 class="roboto text-[1.5rem] font-[600]">Our Services</h2>
    </section>
  </section>

  <!-- dropdown section for services -->
  <section class="p-[1rem_1.5rem] md:p-[2rem_6.5rem] ">

    <!-- Renewable Energy Services -->
    <section class="">
      <article @click="toggleButton1" class="relative z-[3] group cursor-pointer">
        <h2 class="break-words group-hover:text-white group-hover:bg-[black] transition-all duration-200 z-[4] relative border-b-[1px] p-[.875rem_0.5rem] pr-[2rem] border-solid border-b-[#666666] roboto text-[1.417rem] md:text-[1.45rem] font-[600]
          ">
          Renewable Energy Services</h2>

        <button class="absolute top-[1rem] z-[5] right-0 w-[2rem] h-[2rem] grid place-items-center">
          <svg
            :class="['text-[#000036] group-hover:fill-[white] group-hover:opacity-[.8] opacity-[.54] transition-all duration-[200]', button1Active ? 'rotate-0' : '-rotate-180']"
            width="24" height="24" viewBox="0 0 24 24" focusable="false">
            <path d="M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83"></path>
          </svg>
        </button>
      </article>

      <section
        :class="['transition-all flex flex-wrap md:flex-nowrap justify-center items-start mt-[3em] p-[.5rem_.2rem] text-[1rem] overflow-hidden', button1Active ? 'h-fit md:max-h-[45rem] min-h-[13rem]' : 'h-0']">
        <section class="flex flex-wrap items-center justify-center">
          <section class="grid grid-rows-[minmax(18rem, auto)]">

            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/energy-plant.jpg" alt="energy audit" />
          </section>
          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Energy Audits
          </h3>
          <p class="text-center px-[2rem]">
            Thorough assessments to
            increase energy efficiency
            and identify cost-saving
            opportunities.
          </p>
        </section>

        <section class="flex flex-wrap items-center justify-center">
          <section class="h-[auto] mt-[4rem] md:mt-[0]">
            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/solar-panel-install.jpg" alt="solar panel installation" />
          </section>
          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Renewable Installations
          </h3>
          <p class="text-center px-[2rem]">
            Full EPCI services for
            solar-powered mini-grids,
            facilitating the electrification of
            underdeveloped areas.
          </p>
        </section>

        <section class="flex flex-wrap items-center justify-center">
          <section class="grid grid-rows-[minmax(18rem, auto)] mt-[4rem] md:mt-[0]">
            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/solar-panels.jpg" alt="solar panel installation" />
          </section>

          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Solar Panels
          </h3>
          <p class="text-center px-[2rem]">
            Provision of cutting-edge,
            cost-effective equipment for
            solar energy generation and
            administration.
          </p>
        </section>
      </section>

    </section>

    <!-- Oil and Gas Services -->
    <section class='mb-[2rem]'>
      <article @click="toggleButton2" class="relative z-[3] group cursor-pointer">
        <h2
          class="break-words group-hover:text-white group-hover:bg-[black] transition-all duration-200 z-[4] relative border-b-[1px] p-[.875rem_0.5rem] pr-[2rem] border-solid border-b-[#666666] roboto text-[1.417rem] md:text-[1.45rem] font-[600]">
          Oil and Gas Services</h2>

        <button class="absolute top-[1rem] right-0 z-[5] w-[2rem] h-[2rem] grid place-items-center">
          <svg
            :class="['text-[#000036] group-hover:fill-[white] group-hover:opacity-[.8] opacity-[.54] transition-all duration-[1500]', button2Active ? 'rotate-0' : '-rotate-180']"
            width="24" height="24" viewBox="0 0 24 24" focusable="false">
            <path d="M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83"></path>
          </svg>
        </button>
      </article>

      <section
        :class="['transition-all flex flex-wrap md:flex-nowrap justify-center items-start pt-[3rem] p-[0_.2rem] text-[1rem] overflow-hidden', button2Active ? 'h-fit md:max-h-[45rem] min-h-[13rem]' : 'h-0']">
        <section class="flex flex-wrap items-center justify-center">
          <section class="grid grid-rows-[minmax(18rem, auto)]">

            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/procurement.jpg" alt="procurement illustration" />
          </section>
          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Procurement
          </h3>
          <p class="text-center px-[2rem]">
            Procuring premium-grade
            equipment globally across
            various sectors including
            marine, upstream, and
            downstream industries.
          </p>
        </section>

        <section class="flex flex-wrap items-center justify-center">
          <section class="grid grid-rows-[minmax(18rem, auto)] mt-[4rem] md:mt-[0]">
            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/ppe.jpg" alt="PPE illustration" />
          </section>
          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            PPE Supply
          </h3>
          <p class="text-center px-[2rem]">
            Delivering safety equipment
            sourced from globally
            recognised partners while
            leveraging emerging local talent
            to fulfil your safety needs.
          </p>
        </section>

        <section class="flex flex-wrap items-center justify-center">
          <section class="grid grid-rows-[minmax(18rem, auto)] mt-[4rem] md:mt-[0]">
            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/drilling.jpg" alt="drilling and production fluids illustration" />
          </section>

          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Drilling & Production Fluids
          </h3>
          <p class="text-center px-[2rem]">
            Tailored drilling and
            production fluids crafted
            and engineered to enhance
            drilling and production
            efficiency.
          </p>
        </section>
      </section>

    </section>

    <!-- Human Resources Services -->
    <section :class="['mb-[2rem]',]">
      <article @click="toggleButton3" class="relative cursor-pointer group">
        <h2
          class="break-words border-b-[1px] group-hover:text-white group-hover:bg-[black] p-[.875rem_0.5rem] pr-[2rem] border-solid border-b-[#666666] roboto text-[1.417rem] md:text-[1.45rem] font-[600]">
          Human Resources Services</h2>

        <button class="absolute top-[1rem] z-5 right-0 w-[2rem] h-[2rem] grid place-items-center">
          <svg
            :class="['text-[#000036] group-hover:fill-[white] group-hover:opacity-[.8] opacity-[.54] transition-all duration-[1500]', button3Active ? 'rotate-0' : '-rotate-180']"
            width="24" height="24" viewBox="0 0 24 24" focusable="false">
            <path d="M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83"></path>
          </svg>
        </button>
      </article>

      <section
        :class="['transition-all grid md:grid-cols-[auto,_auto,_auto] justify-center items-start pt-[3rem] p-[.5rem_.2rem] text-[1rem] overflow-hidden', button3Active ? 'h-fit md:max-h-[95rem] min-h-[13rem]' : 'h-0']">


        <ServiceCard v-for="(service, index) in services" :key="index" :imgSrc="require(`../assets/${service.imgSrc}`)"
          :imgAlt="service.imgAlt" :headerText="service.headerText" :paragraphText="service.paragraphText" />

        <section class="flex flex-wrap items-center justify-center">
          <section class="grid grid-rows-[minmax(18rem, auto)] mt-[4rem]">

            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/performance.jpg"
              alt="executive coaching and mentoring session with an old man illustration" />
          </section>
          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Executive Coaching and Mentoring
          </h3>
          <p class="text-center px-[2rem]">
            We provide personalized coaching and mentoring programs for senior executives, enhancing their leadership
            skills, decision-making abilities, and overall effectiveness.
          </p>
        </section>
        <section class="flex flex-wrap items-center justify-center mt-[4rem] center-service-element">

          <section class="grid grid-rows-[minmax(18rem, auto)]">

            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/performance-management.jpg"
              alt="performance management illustration" />
          </section>
          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Performance Management
          </h3>
          <p class="text-center px-[2rem]">
            Our performance management frameworks help organizations establish a culture of accountability, set clear
            performance expectations, and drive continuous improvement.
          </p>
        </section>
      </section>

    </section>

    <section :class="['mb-[4rem]',]">
      <article @click="toggleButton4" class="relative cursor-pointer group">
        <h2
          class="break-words border-b-[1px] p-[.875rem_0.5rem] group-hover:text-white group-hover:bg-[black] pr-[2rem] border-solid border-b-[#666666] roboto text-[1.417rem] md:text-[1.45rem] font-[600]">
          Financial Services</h2>

        <button class="absolute top-[1rem] right-0 w-[2rem] h-[2rem] grid place-items-center">
          <svg
            :class="['text-[#000036] group-hover:fill-[white] group-hover:opacity-[.8] opacity-[.54] transition-all duration-[1500]', button4Active ? 'rotate-0' : '-rotate-180']"
            width="24" height="24" viewBox="0 0 24 24" focusable="false">
            <path d="M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83"></path>
          </svg>
        </button>
      </article>

      <section
        :class="['transition-all grid md:grid-cols-[auto,_auto] justify-start items-start pt-[3rem] p-[.5rem_.2rem] text-[1rem] overflow-hidden', button4Active ? 'h-fit md:max-h-[95rem] min-h-[13rem]' : 'h-0']">
        <section class="flex flex-wrap items-center justify-center">
          <section class="grid grid-rows-[minmax(18rem, auto)]">

            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/project-finance.jpg" alt="finance illustration" />
          </section>
          <h3 class="roboto w-[100%] mt-[.1rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Project Financing
          </h3>
          <p class="text-center px-[2rem]">
            Through our collaborations with
            both international and local
            institutions, we offer access to
            project funding at advantageous
            rates.
          </p>
        </section>

        <section class="flex flex-wrap items-center justify-center">
          <section class="grid grid-rows-[minmax(18rem, auto)] mt-[4rem] md:mt-[0]">
            <img class="object-contain max-w-[18rem] min-w-[12rem] w-[100%] h-[16rem]"
              src="../assets/imgs/services-dropdown/finance-modelling.jpg" alt="financial modelling illustration" />
          </section>
          <h3 class="roboto w-[100%] mt-[.5rem] text-center text-[1.117rem] md:text-[1.2rem] font-[600]">
            Financial Analysis &
            Modelling
          </h3>
          <p class="text-center px-[2rem]">
            Drawing upon our in-house expertise,
            we analyze situations, develop forecast
            models, and offer contextual insights
            and recommendations for commercial
            strategies.
          </p>
        </section>

      </section>


    </section>

    <hr class="block mx-auto bg-[rgba(211,211,211,1)] h-[0.0625rem] w-[100%] my-[2.5rem]" />

    <!-- Industry Partnerships and Collaborations -->
    <section class="grid mb-[5rem] overflow-hidden">

      <h2
        class="break-words mb-[2.5rem] p-[.875rem_0.5rem] pr-[2rem] roboto text-[1.417rem] md:text-[1.5em] font-[600]">
        Industry Partnerships and Collaborations</h2>
      <section
        class="w-[100%] md:w-[48rem] place-self-center inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
        <section
          class="flex items-center justify-center md:justify-start [&_section]:mx-[4rem] [&_img]:max-w-none animate-infinite-scroll">
          <section class="w-[100%] flex justify-center gap-[1.5rem] mb-[3rem]">
            <img class="w-[9.5rem] h-auto object-contain" src="../assets/imgs/strategic-partners/WearGrace.png"
              alt="weargrace logo" />
          </section>
          <section class="flex justify-center">
            <img class="place-self-center w-[13rem] h-auto mb-[1.7rem]"
              src="../assets/imgs/strategic-partners/3m-logo.png" alt="3m logo" />
          </section>
          <section class="flex justify-center">
            <img class="place-self-center w-[10rem] h-auto mb-[1.5rem]" src="../assets/imgs/strategic-partners/pts.png"
              alt="protonics technology solutions logo" />
          </section>
        </section>

        <!-- Duplicate for slider -->
        <section
          class="flex items-center justify-center md:justify-start [&_section]:mx-[4rem] [&_img]:max-w-none animate-infinite-scroll"
          aria-hidden="true">
          <section class="w-[100%] flex justify-center gap-[1.5rem] mb-[3rem]">
            <img class="w-[9.5rem] h-auto object-contain" src="../assets/imgs/strategic-partners/WearGrace.png"
              alt="weargrace logo" />
          </section>
          <section class="flex justify-center">
            <img class="place-self-center w-[13rem] h-auto mb-[1.7rem]"
              src="../assets/imgs/strategic-partners/3m-logo.png" alt="3m logo" />
          </section>
          <section class="flex justify-center">
            <img class="place-self-center w-[10rem] h-auto mb-[1.5rem]" src="../assets/imgs/strategic-partners/pts.png"
              alt="protonics technology solutions logo" />
          </section>
        </section>

      </section>
    </section>

    <section class="grid overflow-hidden">
      <h2
        class="break-words mb-[2.5rem] p-[.875rem_0.5rem] pr-[2rem] roboto text-[1.417rem] md:text-[1.5em] font-[600]">
        Clients</h2>

      <section
        class="inline-flex place-self-center md:w-[68rem] flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
        <section
          class="flex items-center justify-center md:justify-start [&_section]:mx-[4rem] [&_img]:max-w-none animate-infinite-scroll duration-[40s]">
          <section class="w-[100%] flex justify-center gap-[.5rem] mb-[3rem]">
            <img class="w-[15.5rem] h-auto object-contain" src="../assets/imgs/regulatory-bodies/nnpc.png"
              alt="nnpc logo" />
          </section>

          <section class="flex justify-center">
            <img class="place-self-center w-[13rem] h-auto mb-[1.7rem]" src="../assets/imgs/regulatory-bodies/ncdmb.jpg"
              alt="ncdb logo" />
          </section>

          <section class="flex justify-center">
            <img class="place-self-center w-[10rem] h-auto mb-[1.5rem]" src="../assets/imgs/clients/nestoil.png"
              alt="Nestoil logo" />
          </section>
          <section class="flex justify-center">
            <img class="place-self-center w-[10rem] h-auto mb-[1.5rem]"
              src="../assets/imgs/clients/ND-Western-1424x802.jpg" alt="ND Western logo" />
          </section>
          <section class="flex justify-center">
            <img class="place-self-center w-[10rem] h-auto mb-[1.5rem]" src="../assets/imgs/clients/seplat.jpg"
              alt="Seplat logo" />
          </section>
        </section>

        <section
          class="flex items-center justify-center md:justify-start [&_section]:mx-[4rem] [&_img]:max-w-none animate-infinite-scroll">
          <section class="w-[100%] flex justify-center gap-[.5rem] mb-[3rem]">
            <img class="w-[15.5rem] h-auto object-contain" src="../assets/imgs/regulatory-bodies/nnpc.png"
              alt="nnpc logo" />
          </section>

          <section class="flex justify-center">
            <img class="place-self-center w-[13rem] h-auto mb-[1.7rem]" src="../assets/imgs/regulatory-bodies/ncdmb.jpg"
              alt="ncdb logo" />
          </section>

          <section class="flex justify-center">
            <img class="place-self-center w-[10rem] h-auto mb-[1.5rem]" src="../assets/imgs/clients/nestoil.png"
              alt="protonics technology solutions logo" />
          </section>

          <section class="flex justify-center">
            <img class="place-self-center w-[10rem] h-auto mb-[1.5rem]"
              src="../assets/imgs/clients/ND-Western-1424x802.jpg" alt="ND Western logo" />
          </section>
          <section class="flex justify-center">
            <img class="place-self-center w-[10rem] h-auto mb-[1.5rem]" src="../assets/imgs/clients/seplat.jpg"
              alt="Seplat logo" />
          </section>

        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { ref } from 'vue';
import { useHead } from '@vueuse/head';
import ServiceCard from './ServiceCard.vue';
export default {

  name: 'ServicesPage',
  mounted() {
    this.$emit('load-images');
  },
  data() {
    return {
      services: [
        {
          imgSrc: "imgs/services-dropdown/business-obj.jpg",
          imgAlt: "Business objectives illustration",
          headerText: "Organization Design & Alignment to Business Objectives",
          paragraphText: "We assist businesses in aligning their organizational structure and processes with their strategic goals, enabling them to operate efficiently and effectively."
        },
        {
          imgSrc: "imgs/services-dropdown/manpower.jpg",
          imgAlt: "construction workers",
          headerText: "Manpower Development",
          paragraphText: "Our training and development programs are designed to enhance the skills and capabilities of your workforce, ensuring they remain competitive in today's rapidly changing business environment."
        },
        {
          imgSrc: "imgs/services-dropdown/leadership.jpg",
          imgAlt: "leadership illustration",
          headerText: "Leadership and Operational Learning Design and Delivery",
          paragraphText: "We provide comprehensive leadership development initiatives that empower your leaders to drive innovation, manage change, and inspire high-performance teams."
        },
        {
          imgSrc: "imgs/services-dropdown/training.jpg",
          imgAlt: "women training illustration",
          headerText: "Training Resourcing",
          paragraphText: "We offer end-to-end training solutions, including content development, instructional design, and training delivery, to meet your organization's specific learning objectives."
        },
        {
          imgSrc: "imgs/services-dropdown/talent.jpg",
          imgAlt: "talent management illustration",
          headerText: "Talent Management",
          paragraphText: "Our talent management strategies help you attract, develop, and retain top talent, ensuring a strong pipeline of future leaders within your organization."
        },

        // Add more objects as needed
      ],
      button1Active: false,
      button2Active: false,
      button3Active: false,
      button4Active: false
    };
  },
  components: {
    ServiceCard,
  },
  methods: {
    toggleButton1() {
      this.button1Active = !this.button1Active;
    },
    toggleButton2() {
      this.button2Active = !this.button2Active;
    },
    toggleButton3() {
      this.button3Active = !this.button3Active;
    },
    toggleButton4() {
      this.button4Active = !this.button4Active;
    },
  },
  setup() {
    const pageTitle = ref('Services - Vesta Atlantic');
    const pageDescription = ref(`Thorough assessments to
            increase energy efficiency
            and identify cost-saving
            opportunities...`);
    const pageOgDescription = ref(`Energy Audits - Thorough assessments to
            increase energy efficiency
            and identify cost-saving
            opportunities...`);
    const pageImage = ref('https://i.ibb.co/PDdQgqg/logo.jpg');
    const pageUrl = ref('https://www.vestaatlantic.com/services');

    useHead({
      title: pageTitle.value,
      meta: [
        { name: 'description', content: pageDescription.value },
        { property: 'og:title', content: pageTitle.value },
        { property: 'og:description', content: pageOgDescription.value },
        { property: 'og:image', content: pageImage.value },
        { property: 'og:url', content: pageUrl.value },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: pageTitle.value },
        { name: 'twitter:description', content: pageOgDescription.value },
        { name: 'twitter:image', content: pageImage.value }
      ]
    });

    return {
      pageTitle,
      pageDescription,
      pageOgDescription,
      pageImage,
      pageUrl
    };
  }
}
</script>