<template>
  <LoaderPage :show="loading" />
  <NavBar />
  <router-view @load-images="checkImagesLoaded"></router-view>
  <FooterComponent />
</template>

<script>
import { ref, onMounted, provide, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import NavBar from './components/NavBar.vue';
import FooterComponent from './components/Footer.vue';
import LoaderPage from './components/LoaderPage.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    FooterComponent,
    LoaderPage
  },
  setup() {
    const loading = ref(true);
    const router = useRouter();

    const checkImagesLoaded = () => {
      const images = Array.from(document.images);
      const imagesPromises = images.map(
        (img) =>
          new Promise((resolve) => {
            if (img.complete) {
              resolve();
            } else {
              img.onload = img.onerror = resolve;
            }
          })
      );

      Promise.all(imagesPromises).then(() => {
        setTimeout(() => {
          loading.value = false;
          nextTick(() => {
            const { hash } = router.currentRoute.value;
            if (hash) {
              const element = document.querySelector(hash);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            } else {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          });
        }, 2300); // Add a slight delay for smooth transition
      });
    };

    onMounted(() => {
      checkImagesLoaded();
    });

    provide('loading', loading);
    provide('checkImagesLoaded', checkImagesLoaded);

    router.beforeEach((to, from, next) => {
      loading.value = true;
      next();
    });

    router.afterEach(() => {
      checkImagesLoaded();
    });

    return { loading, checkImagesLoaded };
  }
}
</script>

<style>
  @import './assets/main.css';
  @import './assets/style-imgs.css';
</style>
