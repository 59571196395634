import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import { MotionPlugin } from '@vueuse/motion';
import { createHead } from '@vueuse/head';
import 'leaflet/dist/leaflet.css';

const app = createApp(App);
const head = createHead();

app.use(MotionPlugin);
app.use(router);

app.use(head);
app.mount('#app');