import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../components/LandingPage.vue';
import ServicesPage from '../components/ServicesPage.vue';
import ProjectPage from '../components/ProjectPage.vue';
import ContactUsPage from '../components/ContactPage.vue';
import CareersPage from '../components/CareersPage.vue';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: LandingPage
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../components/AboutPage.vue')
    },
    {
      path: '/projects',
      name: 'projects',
      component: ProjectPage
    },
    {
      path: '/services',
      name: 'services',
      component: ServicesPage
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactUsPage
    },
    {
      path: '/careers',
      name: 'careers',
      component: CareersPage
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            resolve({ 
              top: element.offsetTop - 100,
              behavior: 'smooth'
            });
          } else {
            resolve({ x: 0, y: 0 });
          }
        }, 100); // Delay to ensure the element is loaded
      });
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
