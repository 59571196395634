<template>
  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
      <img class="w-[100%] h-[4.5rem] object-contain" :src="require('../assets/' + imgURL)" :alt="companyName"/>
    </th>
    <td class="px-6 py-4">
      {{work}}
    </td>
    <td class="px-6 py-4">
      {{dateMY}}
    </td>
    <td class="px-6 py-4">
      <span :class="['font-medium hover:underline', completionStatus === 'Completed' ? 'text-green-600 dark:text-green-500' : 'text-yellow-600 dark:text-yellow-500']">{{completionStatus}}</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ServiceTableRows',
  mounted() {
    this.$emit('load-images');
  },
  props: {
    companyName: {
      type: String,
      required: true,
    },
    work: {
      type: String,
      required: true,
    },
    dateMY: {
      type: String,
      required: true,
    },
    completionStatus: {
      type: String,
      required: true,
    },
    imgURL: {
      type: String,
      required: true
    }
  },
};
</script>
